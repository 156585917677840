import { ROOT_URL } from "../config";

const refreshToken = async () => {
  try {
    // Logic to refresh the token
    // For example, using a refreshToken to get a new accessToken
    let formData = new FormData();
    formData.append("refresh", localStorage.getItem("refresh"));
    const refreshResponse = await fetch(ROOT_URL + "/auth/refresh/", {
      method: "POST",
      body: formData,
    });

    if (!refreshResponse.ok) {
      throw new Error("Could not refresh the token.");
    }

    const data = await refreshResponse.json();
    localStorage.setItem("access", data.access);
    return true;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return false;
  }
};

async function fetchWithTokenRefresh(url, options = {}) {
  // Inject the Authorization header into all requests
  const accessToken = localStorage.getItem("access");
  if (accessToken) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  // Perform the fetch call
  let response = await fetch(url, options);

  // If the response is unauthorized, try to refresh the token
  if (response.status === 401) {
    const didRefresh = await refreshToken();
    if (didRefresh) {
      // Update accessToken after refresh
      const newAccessToken = localStorage.getItem("access");
      options.headers.Authorization = `Bearer ${newAccessToken}`;

      // Retry the fetch call with the new token
      response = await fetch(url, options);
    } else {
      // Handle failure to refresh token, e.g., redirect to login
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      window.location.reload();
      console.error("Failed to refresh token.");
      // Redirect to login or perform other error handling
    }
  }

  return response;
}

const getAgencies = async apiUrl => {
  try {
    const response = await fetch(apiUrl + `/agency/`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getPrograms = async apiUrl => {
  try {
    const response = await fetch(apiUrl + `/program/`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getLocations = async apiUrl => {
  try {
    const response = await fetch(apiUrl + `/citizen/province/`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getDistrict = async apiUrl => {
  try {
    const response = await fetch(apiUrl + `/citizen/district/`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getSortBy = async () => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/citizen-program/sort_options/`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getTotalAvailedServices = async (agency_id, province_id) => {
  try {
    let queryParams = new URLSearchParams();

    if (agency_id) queryParams.append("agency", agency_id);
    if (province_id) queryParams.append("province", province_id);

    const url = `${ROOT_URL}/citizen/citizen-program/?${queryParams.toString()}`;

    const response = await fetchWithTokenRefresh(url, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getAgencyAvailedServices = async (program_id, agency_id, province_id) => {
  try {
    let queryParams = new URLSearchParams();

    if (program_id) queryParams.append("program", program_id);
    if (agency_id) queryParams.append("agency", agency_id);
    if (province_id) queryParams.append("province", province_id);

    const response = await fetch(
      ROOT_URL + `/citizen/citizen-program/availed_services_metrics/?${queryParams.toString()}`,
      {
        method: "GET",
      },
    );

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

// number of registrants
const getOverallMetricsRegistrants = async (apiUrl, agency_id, province_id) => {
  try {
    let queryParams = new URLSearchParams();

    if (agency_id) queryParams.append("agency", agency_id);
    if (province_id) queryParams.append("province", province_id);

    const url = `${apiUrl}/citizen/metrics/?${queryParams.toString()}`;

    const response = await fetchWithTokenRefresh(url, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

// availed of services
const getOverallMetricsAvailedServices = async (apiUrl, agency_id, province_id) => {
  try {
    let queryParams = new URLSearchParams();

    if (agency_id) queryParams.append("agency", agency_id);
    if (province_id) queryParams.append("province", province_id);

    const url = `${apiUrl}/citizen/citizen-program/metrics/?${queryParams.toString()}`;

    const response = await fetchWithTokenRefresh(url, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getLocationMetrics = async (apiUrl, province_id, district_id) => {
  try {
    let queryParams = new URLSearchParams();
    if (province_id) queryParams.append("province", province_id);
    if (district_id) queryParams.append("district", district_id);

    const url = `${apiUrl}/citizen/metrics/?${queryParams.toString()}`;

    const response = await fetchWithTokenRefresh(url, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getAvailedServiceMetrics = async (apiUrl, agency_id, province_id) => {
  try {
    let queryParams = new URLSearchParams();
    if (agency_id) queryParams.append("agency", agency_id);
    if (province_id) queryParams.append("province", province_id);

    const url = `${apiUrl}/citizen/citizen-program/metrics/?${queryParams.toString()}`;

    const response = await fetchWithTokenRefresh(url, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

export {
  getAgencies,
  getPrograms,
  getLocations,
  getDistrict,
  getSortBy,
  getTotalAvailedServices,
  getAgencyAvailedServices,
  getOverallMetricsRegistrants,
  getOverallMetricsAvailedServices,
  getLocationMetrics,
  getAvailedServiceMetrics,
};
