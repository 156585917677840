const apiConfig = {
  BPSF: {
    development: "https://api-dev.bagongpilipinastayo.com",
    production: "https://api.bagongpilipinastayo.com",
  },
  ISIP: {
    development: "https://api-dev.isiptayo.com",
    production: "https://api.isiptayo.com",
  },
  SIBOL: {
    development: "https://api.dev.sibolpinas.com",
    production: "https://api.sibolpinas.com",
  },
  UNLAD_PILIPINAS: {
    development: "https://api.dev.bbmtayo.com",
    production: "https://api.unladpilipinas.com",
  },
  MAGSASAKA: {
    development: "https://api-dev.magsasaka.net",
    production: "https://api.magsasaka.net",
  },
  AKAP: {
    development: "https://api.dev.akappinas.com",
    production: "https://api.akappinas.com",
  },
  PALAY_NATIN: {
    development: "https://api-dev.palaynatin.com",
    production: "https://api.palaynatin.com",
  },
  MALAYA_RICE: {
    development: "https://api-dev.malayariceproject.com",
    production: "https://api.malayariceproject.com",
  },
};

export default apiConfig;
